import { actions as apiActions, sendApiRequest } from "../api/api.actions";
import {
    createFillSurvey,
    initFillSurvey,
    setFillSurvey,
    setFillSurveyPage,
    checkPage,
    nextPage,
    prevPage,
    getFillSurveyPage,
    setFillSurveyQuestions,
    sendFillSurveyAnswers,
    setFillSurveyUnansweredPages,
    setFillSurveyShowPopup,
    clearFillSurvey,
    checkFillSurvey,
    clearFillSurveyQuestions,
} from "./survey-fill.actions";
import { config } from "../../config";
import { selectFirstSurveyId } from "../survey/survey.selectors";
import { getSurveyList, setSurvey } from "../survey/survey.actions";
import { FILL_SURVEY } from "./survey-fill.reducer";
import {
    selectFillSurveyId,
    selectFillSurveyPageCount,
    selectFillSurveyPageIndex,
    selectFillSurveyQuestionsByPageIndex,
    selectFillSurveyToken,
    selectFillSurveyUnansweredPagesCollection,
} from "./survey-fill.selectors";
import { selectEntityIsLoading } from "../ui/ui.selectors";
import { SURVEY } from "../survey/survey.reducer";
import windowAccessible from "../../utils/check-window";
import { setAlert } from "../alerts/alerts.actions";
import { checkTargetIndex, checkUnfilledSessions } from "./survey-fill.utils";
import { loginUser, logoutUser, registerUser } from "../user/user.actions";
import { selectUserLatestSurveySessionId } from "../user/user.selectors";

const { entity, sessions, pages, questions } = config.endpoints.surveys;
const { htmlCodes } = config;

const surveyFillMiddleware = (store) => (next) => (action) => {
    next(action);

    const { dispatch, getState } = store;
    const { type, payload, meta = {} } = action;

    const surveyId = selectFirstSurveyId(getState());
    const sessionId = selectFillSurveyId(getState());
    const pageIndex = selectFillSurveyPageIndex(getState());
    const pageCount = selectFillSurveyPageCount(getState());
    const surveySessionToken = selectFillSurveyToken(getState());
    const unansweredPages = selectFillSurveyUnansweredPagesCollection(getState());
    const isSurveyLoading = selectEntityIsLoading(getState(), SURVEY);

    const locationPageIndex = windowAccessible() ? window.location.pathname.split("/").pop() : "";

    let fetchConfig, pageQuestions, targetIndex, unfilledSession;

    switch (type) {
        case setSurvey().type:
        case initFillSurvey().type:
        case setFillSurvey().type:
            if (!surveyId && !sessionId && !isSurveyLoading) {
                dispatch(getSurveyList());
            }
            if (surveyId && !sessionId) {
                dispatch(createFillSurvey());
            }
            if (sessionId && window.location.pathname.includes("/ankieta")) {
                dispatch(checkPage(meta.pageIndex ? meta.pageIndex : locationPageIndex));
            }
            break;

        case createFillSurvey().type:
            fetchConfig = {
                url: `${entity}/${surveyId}${sessions}`,
                method: `post`,
            };
            dispatch(sendApiRequest(fetchConfig, { ...meta, entity: FILL_SURVEY }));
            break;

        case `${createFillSurvey().type} ${apiActions.API_SUCCESS}`:
            dispatch(
                setFillSurvey({
                    ...payload.session,
                    surveySessionToken: payload.surveySessionToken,
                })
            );
            break;

        case `${createFillSurvey().type} ${apiActions.API_FAIL}`:
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: "Nie udało się utworzyć Twojej ankiety",
                    },
                    meta
                )
            );
            break;

        case checkFillSurvey().type:
            const userSessionId = selectUserLatestSurveySessionId(getState());
            fetchConfig = {
                url: `${entity}${sessions}/${userSessionId}`,
                method: `get`,
                withCredentials: true,
            };
            dispatch(sendApiRequest(fetchConfig, { ...meta, entity: FILL_SURVEY }));
            break;

        case `${checkFillSurvey().type} ${apiActions.API_SUCCESS}`:
            const pageIndexFromCheck = payload.unansweredPages[0] || payload.pageCount + 1;
            dispatch(clearFillSurveyQuestions());
            dispatch(setFillSurvey(payload, { pageIndex: pageIndexFromCheck }));
            break;

        case `${registerUser().type} ${apiActions.API_SUCCESS}`:
            if (meta.context?.surveySessionToken === surveySessionToken) {
                dispatch(setFillSurveyShowPopup(false));
                dispatch(getFillSurveyPage(pageIndex));
            }
            break;

        case `${loginUser().type} ${apiActions.API_SUCCESS}`:
            unfilledSession = checkUnfilledSessions(payload?.surveySessions);
            if (unfilledSession) {
                dispatch(
                    setFillSurvey({
                        ...unfilledSession,
                        pageIndex: unfilledSession.unansweredPages[0],
                    })
                );
            } else {
                dispatch(clearFillSurvey());
            }
            break;

        case `${logoutUser().type} ${apiActions.API_SUCCESS}`:
            dispatch(clearFillSurvey());
            break;

        case checkPage().type:
            targetIndex = checkTargetIndex(payload, pageCount, unansweredPages);
            dispatch(setFillSurveyPage(targetIndex));
            if (targetIndex <= pageCount) {
                dispatch(getFillSurveyPage(targetIndex));
            }
            break;

        case nextPage().type:
            dispatch(sendFillSurveyAnswers(payload, { formId: meta.formId, index: pageIndex }));
            break;

        case prevPage().type:
            dispatch(checkPage(pageIndex - 1));
            break;

        case getFillSurveyPage().type:
            pageQuestions = selectFillSurveyQuestionsByPageIndex(getState(), payload);

            if (pageQuestions === null && Number.isSafeInteger(Number(payload))) {
                fetchConfig = {
                    url: `${entity}${sessions}/${sessionId}${pages}/${payload}${questions}`,
                    params: { surveySessionToken },
                    withCredentials: true,
                };

                dispatch(
                    sendApiRequest(fetchConfig, {
                        ...meta,
                        index: payload,
                        entity: FILL_SURVEY,
                    })
                );
            }
            break;

        case `${getFillSurveyPage().type} ${apiActions.API_SUCCESS}`:
            if (Number.isSafeInteger(meta.index) && Array.isArray(payload)) {
                dispatch(setFillSurveyQuestions({ index: meta.index, items: payload }));
            }
            break;

        case `${getFillSurveyPage().type} ${apiActions.API_FAIL}`:
            if (payload.status === 401) {
                dispatch(setFillSurveyShowPopup(true));
            } else {
                dispatch(
                    setAlert(
                        {
                            type: "error",
                            content: "Nie udało się pobrać pytań",
                        },
                        meta
                    )
                );
            }

            break;

        case sendFillSurveyAnswers().type:
            fetchConfig = {
                url: `${entity}${sessions}/${sessionId}${questions}`,
                method: "patch",
                params: { surveySessionToken },
                data: payload,
                withCredentials: true,
            };

            dispatch(sendApiRequest(fetchConfig, { ...meta, entity: FILL_SURVEY }));
            break;

        case `${sendFillSurveyAnswers().type} ${apiActions.API_SUCCESS}`:
            console.log('sendFillSurveyAnswers: ',payload);
            console.log('sendFillSurveyAnswers - meta: ',meta);
            console.log('sendFillSurveyAnswers - metaIndex: ',meta.index);
            console.log('sendFillSurveyAnswers - unansweredPages: ',unansweredPages);
            dispatch(setFillSurveyQuestions({ index: meta.index, items: payload }));
            dispatch(
                setFillSurveyUnansweredPages(
                    unansweredPages.filter((index) => index !== meta.index),
                    meta
                )
            );
            break;

        case `${sendFillSurveyAnswers().type} ${apiActions.API_FAIL}`:
            const getMsg = (status) => {
                switch (status) {
                    case 401:
                        return htmlCodes["401"].survey;
                    case 403:
                        return htmlCodes["403"].survey;
                    default:
                        return "Nie udało się wysłać odpowiedzi";
                }
            };
            dispatch(
                setAlert(
                    {
                        type: "error",
                        content: getMsg(payload.status),
                    },
                    meta
                )
            );
            break;

        case setFillSurveyUnansweredPages().type:
            dispatch(checkPage(pageIndex + 1, meta));
            break;

        // no default
    }
};

export default surveyFillMiddleware;
